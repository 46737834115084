const blogConfig = {

    blogPosts: [
    {
      id: 1,
      image: 'blog-1.jpg',
      alt: 'Design conferences in 2022',
      category: 'Design',
      date: '2022-02-23',
      title: 'Design conferences in 2022',
      text: 'Veritatis et quasi architecto beatae vitae dicta sunt, explicabo.',
      link: 'https://www.google.com',
    },
    {
      id: 2,
      image: 'blog-2.jpg',
      alt: 'Best fonts every designer',
      category: 'Design',
      date: '2022-02-23',
      title: 'Best fonts every designer',
      text: 'Sed ut perspiciatis, nam libero tempore, cum soluta nobis est eligendi.',
      link: 'https://www.google.com',
    },
    {
      id: 3,
      image: 'blog-3.jpg',
      alt: 'Design digest #80',
      category: 'Design',
      date: '2022-02-23',
      title: 'Design digest #80',
      text: 'Excepteur sint occaecat cupidatat no proident, quis nostrum exercitationem ullam corporis suscipit.',
      link: 'https://www.google.com',
    },
    {
      id: 4,
      image: 'blog-4.jpg',
      alt: 'UI interactions of the week',
      category: 'Design',
      date: '2022-02-23',
      title: 'UI interactions of the week',
      text: 'Enim ad minim veniam, consectetur adipiscing elit, quis nostrud exercitation ullamco laboris nisi.',
      link: 'https://www.google.com',
    },
    {
      id: 5,
      image: 'blog-5.jpg',
      alt: 'The forgotten art of spacing',
      category: 'Design',
      date: '2022-02-23',
      title: 'The forgotten art of spacing',
      text: 'Maxime placeat, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      link: 'https://www.google.com',
    },
    {
      id: 6,
      image: 'blog-6.jpg',
      alt: 'Design digest #79',
      category: 'Design',
      date: '2022-02-23',
      title: 'Design digest #79',
      text: 'Optio cumque nihil impedit uo minus quod maxime placeat, velit esse cillum.',
      link: 'https://www.google.com',
    },
  ],
}

export default blogConfig;